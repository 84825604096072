import { Suspense, useEffect, useState, useContext } from 'react';
import ComponentDefault from './i18n/Onboard-en.js';
import getLocalePage from '../../getLocalePage.js';

import './Onboard.scss';

function Page(props) {
  const {LanguageContext} = props;
  const {language, languageDirection} = useContext(LanguageContext);
  const [LoadComponent, setLoadComponent] = useState(null);

  useEffect(() => {
    (async() => {
      setLoadComponent(await getLocalePage('ext/onboard', language, props));
    })();
  }, [language]);

  return LoadComponent ? <Suspense><LoadComponent {...props} /></Suspense> : <ComponentDefault {...props} />;
}

export default Page;