import { Link } from 'react-router-dom';

function ExtOffboard() {

    return (
        <div>
            <div id="hero">
                <h2 id="tagline">We're sorry to to see you go..</h2>
            </div>

            <main>
                <section>
                    <h3 className="mt-5">Your feedback is valuable!</h3>
                    <p className="text">
                        Please visit our <Link to="/support">Help &amp; Support</Link> page to share your comments.
                    </p>
                </section>
            </main>
        </div>
    );
}

export default ExtOffboard;