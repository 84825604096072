import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import i18n from './i18n';

// import HelpWidgetManager from './classes/HelpWidgetManager';
import ContactFormManager from './classes/ContactFormManager';

import Container from './templates/Container';
import Home from './pages/home/Home';
import Support from './pages/support/Support';
import Privacy from './pages/privacy/Privacy';
import ChangeLog from './pages/changelog/ChangeLog';
import ExtOnboard from './pages/ext/onboard/Onboard';
import ExtOffboard from './pages/ext/offboard/Offboard';

import './index.scss';

i18n.init();

const { 
    // REACT_APP_RECAPTCHA_SITE_KEY,
    REACT_APP_FORM_TOKEN_URL,
    REACT_APP_FORM_SUBMIT_URL,
    REACT_APP_CHROME_EXTENSION_ID,
    REACT_APP_CHROME_EXTENSION_URL,
    REACT_APP_EDGE_EXTENSION_ID,
    REACT_APP_EDGE_EXTENSION_URL
} = process.env;

const isEdge = (navigator.userAgentData?.brands||[]).find(brand => brand.brand === 'Microsoft Edge');
const isChromium = (navigator.userAgentData?.brands||[]).find(brand => brand.brand === 'Chromium');
const extensionUrl = isEdge ? REACT_APP_EDGE_EXTENSION_URL : (isChromium ? REACT_APP_CHROME_EXTENSION_URL : null);

// const helpWidgetManager = new HelpWidgetManager({ displayFloatingButton: false });
// console.log(process.env);
const contactFormManager = new ContactFormManager({
    // recaptchaSiteKey: REACT_APP_RECAPTCHA_SITE_KEY, 
    // recaptchaContainer: document.getElementById('recaptchaContainer'),
    formTokenUrl: REACT_APP_FORM_TOKEN_URL,
    formSubmitUrl: REACT_APP_FORM_SUBMIT_URL
});

const LanguageContext = createContext();

const containerProps = {
    extensionUrl,
    LanguageContext
}

const pathToComponents = {
    "/": Home,
    "/support": Support,
    "/privacy/:lang?": Privacy,
    "/changelog": ChangeLog,
    "/ext/onboard": ExtOnboard,
    "/ext/offboard/:c?": ExtOffboard,
};

const routerObjects = Object.keys(pathToComponents).map(path => {
    const ComponentToRender = pathToComponents[path];
    return {
        path,
        // element: <Container helpWidgetManager={helpWidgetManager}><component helpWidgetManager={helpWidgetManager} /></Container>,
        element: <Container contactFormManager={contactFormManager} {...containerProps}><ComponentToRender contactFormManager={contactFormManager} {...containerProps} /></Container>,
    }
});

const router = createBrowserRouter(routerObjects);

// displayHelpWidget will be false by default - we'll rely on a help navigation link to go to the help page
// const router = createBrowserRouter([
//     {
//         path: "/",
//         // element: <Container helpWidgetManager={helpWidgetManager}><Home helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><Home contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
//     {
//         path: "/support",
//         // element: <Container helpWidgetManager={helpWidgetManager}><Support helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><Support contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
//     {
//         path: "/privacy",
//         // element: <Container helpWidgetManager={helpWidgetManager}><Privacy helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><Privacy contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
//     {
//         path: "/changelog",
//         // element: <Container helpWidgetManager={helpWidgetManager}><ChangeLog helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><ChangeLog contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
//     {
//         path: "/ext/onboard",
//         // element: <Container helpWidgetManager={helpWidgetManager}><ExtOnboard helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><ExtOnboard contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
//     {
//         path: "/ext/offboard/:c?",
//         // element: <Container helpWidgetManager={helpWidgetManager}><ExtOffboard helpWidgetManager={helpWidgetManager} /></Container>,
//         element: <Container contactFormManager={contactFormManager} {...containerProps}><ExtOffboard contactFormManager={contactFormManager} {...containerProps} /></Container>,
//     },
// ]);


const root = ReactDOM.createRoot(document.getElementById('root'));

if (
    // !REACT_APP_RECAPTCHA_SITE_KEY || 
    !REACT_APP_FORM_TOKEN_URL ||
    !REACT_APP_FORM_SUBMIT_URL
) {
    root.render(
        <React.StrictMode>
            <div>
                <p>Missing Configuration</p>
            </div>
        </React.StrictMode>
    );
} else {
    root.render(
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
}