import { Suspense, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ComponentDefault from './i18n/Privacy-en.js';
import getLocalePage from '../getLocalePage.js';

function Page(props) {
  const {LanguageContext} = props;
  const {language, languageDirection} = useContext(LanguageContext);
  const [LoadComponent, setLoadComponent] = useState(null);

  useEffect(() => {
    (async() => {
      setLoadComponent(await getLocalePage('privacy', language, props));
    })();
  }, [language]);

  const { lang } = useParams();

  if (lang && lang === 'en') {
    return <ComponentDefault {...props} />;
  }

  return LoadComponent ? <Suspense><LoadComponent {...props} /></Suspense> : <ComponentDefault {...props} />;
}

export default Page;