import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSquareCheck, faBullhorn, faCircleDown } from '@fortawesome/free-solid-svg-icons'

function Home({extensionUrl}) {
  return (
    <div>
        <div id="hero">
          <h2 id="tagline">Watch &amp; learn... a new language.</h2>
        </div>

        <main>
            <div id="about">
              <section className="ps-3 pe-3 pt-5 pb-5">
                  <div className="mb-4 d-flex justify-items-center">
                    <img src="/images/onboarding/watch-video.png" alt="Lingo Layer" className="netflix-hero" />
                  </div>
                  <p className="lead">
                    <b>Learn a new language</b> while you watch Netflix. <b>Lingo Layer</b> transforms your videos into language learning experiences.
                  </p>
                  <p className="lead ps-2">
                    <FontAwesomeIcon icon={faCheck} size="lg" className="me-2" /> Study a new language in a <b>natural &amp; interactive context</b>.
                  </p>
                  <p className="lead ps-2">
                    <FontAwesomeIcon icon={faCheck} size="lg" className="me-2" /> Learn to <b>comprehend spoken dialogue</b>.
                  </p>
                  <p className="mb-0 lead ps-2">
                    <FontAwesomeIcon icon={faCheck} size="lg" className="me-2" /> Access <b>real-time translations</b> in your native language.
                  </p>
            
                  <hr className="mt-4 mb-4" />
                  <div className="text-center">
                    <a href={extensionUrl || "https://chromewebstore.google.com/detail/lingo-layer-language-lear/pkghojjmjelgjbjinopdlmilndlnhjbe"} className="btn btn-warning">
                      <FontAwesomeIcon icon={faCircleDown} size="lg" className="me-1" /> Download Lingo Layer
                    </a>
                  </div>
                  <hr className="mt-4 mb-0" />
              </section>
            </div>

            <div id="features">
                <section className="ps-3 pe-3 pt-5 pb-5">
                    <h2>Features</h2>
                    <ul className="feature-list">
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Browser extension (Chrome, Opera, Edge, Brave)</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Netflix videos</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Display multiple subtitles simultaneously</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Smart matching of audio &amp; subtitles</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> View subtitles in sidebar, overlaid on video or both</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Bookmark subtitles and easily return to the video to rewatch them</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Optional playback pause when hovering over subtitles</li>
                        <li><FontAwesomeIcon icon={faSquareCheck} size="lg" /> Manage text and background color of subtitles for better contrast</li>
                        <li className="coming-soon"><FontAwesomeIcon icon={faBullhorn} size="lg" /> <b>Coming Soon:</b> Firefox &amp; Safari browser extensions</li>
                        <li className="coming-soon"><FontAwesomeIcon icon={faBullhorn} size="lg" /> <b>Coming Soon:</b> Youtube, Amazon Prime, Hulu and more</li>
                        <li className="coming-soon"><FontAwesomeIcon icon={faBullhorn} size="lg" /> <b>Coming Soon:</b> Translate subtitles on demand to unavailable languages</li>
                    </ul>
                </section>
            </div>
        </main>
    </div>
  );
}

export default Home;
