import { useEffect, useState } from 'react';
import SupportedLanguages from '../languages';
import i18n from '../i18n';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faCircleDown } from '@fortawesome/free-solid-svg-icons'

import './Container.scss';

function Container({ LanguageContext, extensionUrl, children }) {

  const [language, setLanguage] = useState(i18n.getLanguage());
  const [languageDirection, setLanguageDirection] = useState(i18n.getLanguageDirection());
  const [installed, setInstalled] = useState(window._lingoLayer?.extensionEnabled);

  const sortLanguagesByName = () => {
    return Object.keys(SupportedLanguages).sort((a, b) => i18n.t('languages:' + a).localeCompare(i18n.t('languages:' + b)));
  };

  const [availableLanguageCodes, setAvailableLanguageCodes] = useState(sortLanguagesByName());

  const { t } = useTranslation();

  useEffect(() => {
    const updateLanguage = () => {
      setLanguage(i18n.getLanguage());
      setLanguageDirection(i18n.getLanguageDirection());
      setAvailableLanguageCodes(sortLanguagesByName());
    };

    i18n.onLanguageUpdateComplete(updateLanguage);
    return () => {
      i18n.offLanguageUpdateComplete(updateLanguage);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return (
    <div className="App">
      <header>
        <nav className="navbar d-flex justify-items-between">
          <h1 id="logo" className="navbar-brand">
            <Link to="/" className="d-flex align-items-end" title="Lingo Layer">
              <img src="/images/logo3-transparent.png" alt="Lingo Layer Logo" width="40" />
              <span className="ms-2">Lingo Layer</span>
            </Link>
          </h1>
          
          <div className="d-flex align-items-baseline">
            <div className="ms-2 me-2">{t('nav.language', 'Language')}:</div>
            <select className="form-select" value={language} onChange={e => i18n.changeLanguage(e.target.value)}>
              <option value="">Unsupported Language</option>
              {availableLanguageCodes.map(l => <option key={l} value={l}>{t('languages:' + l, SupportedLanguages[l])}</option>)}
            </select>
          </div>

          <div className="d-flex align-items-center nav-links">
            {
              extensionUrl ? <a href={extensionUrl} className="me-3">
                <FontAwesomeIcon icon={faCircleDown} className="me-2" />
                {t('nav.browserExtensionLink', 'Browser Extension')}
              </a> : null
            }
            <Link to="/support">
              <FontAwesomeIcon icon={faCircleQuestion} className="me-2" />
              {t('nav.help', 'Help')}
            </Link>
          </div>
        </nav>
      </header>

      <div className="Main" dir={languageDirection}>
        <LanguageContext.Provider value={{ language, languageDirection }}>
          {children}
        </LanguageContext.Provider>
      </div>

      <footer>
        <div className="inner d-flex justify-content-between align-items-center">
          <p>&copy; {new Date().getFullYear()} Lingo Layer - {t('nav.allRightsReserved', 'All Rights Reserved.')} <Link to="/privacy">{t('nav.privacyPolicy', 'Privacy Policy')}</Link></p>
          <div>
          {
              extensionUrl ? <a href={extensionUrl} className="me-3">
                <FontAwesomeIcon icon={faCircleDown} className="me-1" />
                {t('nav.browserExtensionLink', 'Browser Extension')}
              </a> : null
            }
            <Link to="/support">
              <FontAwesomeIcon icon={faCircleQuestion} className="me-1" />
              {t('nav.help', 'Help')}
            </Link>
          </div>
        </div>
      </footer>

      {/* <div id="jsd-widget-bg" onClick={helpWidgetManager.hideForm.bind(helpWidgetManager)}></div> */}
    </div>
  );
}

export default Container;