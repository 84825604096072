import { Suspense, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ComponentDefault from './i18n/Offboard-en.js';
import getLocalePage from '../../getLocalePage.js';

import './Offboard.scss';

function Page(props) {
  const {LanguageContext} = props;
  const {language, languageDirection} = useContext(LanguageContext);
  const [LoadComponent, setLoadComponent] = useState(null);

  useEffect(() => {
    (async() => {
      setLoadComponent(await getLocalePage('ext/offboard', language, props));
    })();
  }, [language]);
    
  const { c } = useParams();

  useEffect(() => {
      if (c && fetch) {
          fetch("https://e.lingolayer.com/p", {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({e: 'uninstall', c})
          }).catch(() => {});
      }
  }, [c]);

  return LoadComponent ? <Suspense><LoadComponent {...props} /></Suspense> : <ComponentDefault {...props} />;
}

export default Page;