import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// import translation from '../public/i18n/it/translation.json';
// import languages from '../public/i18n/it/languages.json';
// import translation from '../public/i18n/en/translation.json';
// import languages from '../public/i18n/en/languages.json';

const availableLanguages = [
    'af', 'ar', 'bg', 'bn', 'da', 'de', 'en', 'es', 'es-ES', 'fa', 'fi',
    'fr', 'ga', 'he', 'hi', 'hr', 'hu', 'id', 'is', 'it', 'ja', 'ko',
    'nb', 'ne', 'nl', 'nn', 'pl', 'pt', 'pt-BR', 'ru', 'sv', 'th', 'tr',
    'uk', 'ur', 'vi',
    // 'zh-CN', // chrome uses this as a catch-all for Simplified Chinese. Since there are many other dialects that could be forced into this choice, omit it and force all of these users to use zh-Hans (zh) below
    'zh', 'zh-Hant'
];

const fallbackLanguages = {
    'no': 'nb',
    // 'zh-CN': 'zh',
    'zh-HK': 'zh-Hant',
    'zh-MO': 'zh-Hant',
    // 'zh-SG': 'zh',
    'zh-TW': 'zh-Hant',
    // 'zh-Hans': 'zh'
}

// const resources = {
//     // it: { translation, languages },
//     en: { translation, languages },
// };

class i18nClass {
    constructor() {
        // console.log("i18nClass instantiated");
        this._i18next = i18next;
        this.intialized = false;
        this.availableLanguages = availableLanguages;
    }

    languageUpdateCompleteCallbacks = [];

    onLanguageUpdateComplete(callback) {
        this.languageUpdateCompleteCallbacks.push(callback);
    }

    offLanguageUpdateComplete(callback) {
        this.languageUpdateCompleteCallbacks = this.languageUpdateCompleteCallbacks.filter((cb) => cb !== callback);
    }

    changeLanguage(languageCode) {
        this._i18next.changeLanguage(languageCode);
    }

    async init() {
        if (this.intialized) {
            return;
        }

        return new Promise((resolve, reject) => {
            if (!this._i18next.isInitialized && !this._i18next.isInitializing) {
                (async () => {
                    // console.log("Initializing i18n", resources);

                    // This will work for the initial language too since it gets selected below
                    this._i18next.on('languageChanged', async (languageCode) => {
                        // console.log("Language changed to:", languageCode);
                        // if we need to load a fallback, change the i18next language so we can properly use i18n.getLanguage()
                        if (!availableLanguages.includes(languageCode)) {
                            if (fallbackLanguages[languageCode]) {
                                this._i18next.changeLanguage(fallbackLanguages[languageCode]);
                                return;
                            } else if (languageCode.includes("-")) {
                                this._i18next.changeLanguage(languageCode.split("-")[0]);
                                return;
                            }
                        }

                        // fall back to en if language is not available after other attempts
                        if (!availableLanguages.includes(languageCode)) {
                            this._i18next.changeLanguage("en");
                            return;
                        }

                        if (availableLanguages.includes(languageCode)) {
                            for (const ns of ['translation', 'languages']) {
                                // console.log("Fetching", languageCode, ns);
                                const response = await fetch(`/i18n/${languageCode}/${ns}.json`);
                                this._i18next.addResourceBundle(languageCode, ns, await response.json());
                            }
                        } else {
                            console.log("Language not available", languageCode);
                        }
                        if (this.languageUpdateCompleteCallbacks.length) {
                            // console.log("calling languageUpdateCompleteCallback");
                            for (const callback of this.languageUpdateCompleteCallbacks) {
                                callback();
                            }
                        }

                        // on the initial load, wait for languages to load before we complete the initialization
                        if (!this.intialized) {
                            this.intialized = true;
                            resolve();
                        }
                    });

                    await this._i18next
                        .use(LanguageDetector)
                        .use(initReactI18next)
                        .init({
                            // debug: true,
                            resources: {},
                            // lng: "he",
                            fallbackLng: "en", // this probably isn't helpful since we need to manually change the language above to avoid getLanguage() being inconsistent with the loaded language
                            interpolation: {
                                escapeValue: false // react already safes from xss
                            },
                            detection: {
                                order: ['navigator'],
                                caches: []
                            }
                        });

                    setTimeout(() => {
                        // we should do this after a language is selected and loaded, but in case no language is selected.. let's do it here
                        if (!this.intialized) {
                            this.intialized = true;
                            resolve();
                        }
                    }, 2000);
                })();
            } else {
                resolve();
            }
        });
    }

    getLanguage() {
        return this._i18next.language;
    }

    // getLanguages() {
    //     return this._i18next.languages;
    // }

    getLanguageDirection() {
        // console.log("getLanguageDirection", this.getLanguage(), this._i18next.dir(this.getLanguage()));
        return this._i18next.dir(this.getLanguage());
    }

    isEnglishLoaded() {
        return this._i18next.isInitialized && (this._i18next.language === "en" || this._i18next.language.startsWith('en-'));
    }

    t() {
        return this._i18next.t(...arguments);
    }
}

const i18n = new i18nClass();

export default i18n;