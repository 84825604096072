import { lazy } from 'react';
import SupportedLanguages from '../languages';

const components = {};
for (const [key, name] of Object.entries({
    'home': 'Home',
    'privacy': 'Privacy',
    'ext/onboard': 'Onboard',
    'ext/offboard': 'Offboard',
})) {
    components[key] = {};
    for (const lang of Object.keys(SupportedLanguages)) {
        components[key][lang] = lazy(() => import(`./${key}/i18n/${name}-${lang}.js`));
    }
}

const getLocalePage = async(name, locale) => {
    return components[name][locale] || null;
}

export default getLocalePage;