import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

function Privacy() {

    return (
        <div>
            <div id="hero">
                <h2 id="tagline">Privacy Policy</h2>
            </div>

            <main className="p-4">
                <section style={{textAlign: 'justify'}}>
                    <div>
                        <Link to="/">
                            <FontAwesomeIcon icon={faAngleLeft} size="sm" className="me-1" />
                            Home
                        </Link>
                    </div>

                    <h4 className="mt-4 mb-3">Effective Date: February 12, 2024</h4>

                    {/**
                     // When translating this file to other languages this should be uncommented
                     <div className="alert alert-warning">
                        <div>
                            This Privacy Policy was translated from the English version, available
                            at <a href="https://www.lingolayer.com/privacy/en" target="_blank" rel="noreferrer">https://www.lingolayer.com/privacy/en</a>.
                            You are encouraged to read and understand the original English version
                            since it will be considered authoritative with respect to any discrepancies.
                        </div>
                    </div>
                     */}
                    
                    <p>
                        This Privacy Policy describes how Waqthru LLC ("we", "us", "our") collects, uses, and shares
                        information about you in connection with your use of the Lingo Layer website (the "Website"),
                        browser extension (the "Extension") and any related services (collectively, the "Services").
                        By using the Services, you agree to the collection and use of information in accordance with
                        this policy. Please read it carefully.
                    </p>

                    <h3 className="mt-4 mb-4">Information We Collect</h3>

                    <h4 className="mt-4 mb-4">Website</h4>

                    <p>
                        <b>Support Tickets:</b>
                    </p>

                    <p>
                        The Website includes a support ticket feature through which we collect your name, email address, and details
                        of your support request. This information is stored in a protected database and is used solely for the purpose
                        of responding to and managing your support requests.
                    </p>

                    <h4 className="mt-4 mb-4">Extension</h4>

                    <p>
                        <b>We Do Not Collect Personal Data:</b>
                    </p>

                    <p>
                        When you use the Extension in its current form, we do not collect any personal data from you. Personal
                        data refers to any information that can be used to identify you personally, such as your name, email address,
                        or IP address.
                    </p>
                    
                    <p>
                        <b>We May Collect Anonymous, Aggregated Data:</b>
                    </p>

                    <p>
                        You have the option to allow anonymous aggregation data to be sent to us. By default, this option is enabled.
                        This data is strictly non-personal and is used solely to identify trends and improvement opportunities for the Services.
                        Examples of such data include how frequently certain features are used, the languages being studied, the videos
                        being played and the quantity of subtitles being saved. If collected, this data will not include any personal or identifiable
                        information attached.
                    </p>

                    <p>
                        <b>Browsing Data May Be Stored Locally on Your Computer:</b>
                    </p>

                    <p>
                        Some Extension features may require local storage of your browsing data. For example, when saving a bookmark the Extension
                        will store the details of the video, the timestamp and the subtitles to local storage.
                    </p>
                    
                    <p>
                        <b>Account Registration:</b>
                    </p>
                    
                    <p>
                        We may, in the future, introduce the option to create an account with Lingo Layer, which would require providing your
                        email address and name. This section of the policy will be updated accordingly to reflect any changes in our data
                        collection practices.
                    </p>
                    
                    <h3 className="mt-4 mb-4">How We Use Information</h3>

                    <h4 className="mt-4 mb-4">Website</h4>

                    <p>
                        Information collected through the Website's support ticket feature is used exclusively to address and
                        manage support issues. It is not used for tracking, analytics, marketing or any other non-support purpose.
                    </p>
                    
                    <h4 className="mt-4 mb-4">Extension</h4>

                    <p>
                        <b>The anonymous aggregated data we collect is used to:</b>
                    </p>

                    <ul>
                        <li>
                            Evaluate what languages are being learned and which features are being used for analytics purposes
                            to understand how people use the Extension so that we can improve it.
                        </li>
                    </ul>

                    <h3 className="mt-4 mb-4">Sharing of Information</h3>
                    
                    <p>
                        We do not share any personal or aggregated data with third parties.
                    </p>

                    <h3 className="mt-4 mb-4">Data Storage</h3>

                    <p>
                        We take reasonable measures to protect your data from unauthorized access, alteration, or disclosure.
                        Transfer is data is secured through SSL encryption. Data is stored in a password protected, encrypted database.
                    </p>

                    <p>
                        We will retain personal data as necessary to provide the Services, comply with our legal obligations,
                        resolve disputes, and enforce our agreements.
                    </p>

                    <p>
                        For data collected via the Website's support ticket feature, you may request the removal of your personal
                        information by submitting a support request. We will process such requests in accordance with applicable
                        laws and regulations.
                    </p>

                    <h3 className="mt-4 mb-4">Your Data Protection Rights Under GDPR</h3>
                    
                    <p>
                        <b>If you are within the EU you are entitled to the following:</b>
                    </p>

                    <p>
                        The right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.
                    </p>

                    <p>
                        The right to rectification - You have the right to request that we correct any information you believe is inaccurate.
                        You also have the right to request that we complete the information you believe is incomplete.
                    </p>

                    <p>
                        The right to erasure - You have the right to request that we erase your personal data, under certain conditions.
                    </p>

                    <p>
                        The right to restrict processing - You have the right to request that we restrict the processing of your personal data, under certain conditions.
                    </p>

                    <p>
                        The right to object to processing - You have the right to object to our processing of your personal data, under certain conditions.
                    </p>

                    <p>
                        The right to data portability - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </p>

                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us using the contact information provided below.
                    </p>
                    
                    <h3 className="mt-4 mb-4">Changes to This Privacy Policy</h3>
                    
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
                        Privacy Policy on this page and updating the "Effective Date" at the top. You are advised to review this
                        Privacy Policy periodically for any changes.
                    </p>
                    
                    <h3 className="mt-4 mb-4">Contact Us</h3>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us by opening a <a href="/support">support ticket</a>.
                    </p>
                </section>
            </main>
        </div>
    );
}

export default Privacy;